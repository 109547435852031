@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,900&display=swap');body{
  color:#2b292b;
}




@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  font-family: 'Roboto', sans-serif;
}
.hasbk{
  background: url('/public//images/bk-stiati.png') no-repeat center top;
  @apply bg-cover lg:bg-contain
}
.box-blue{
  background: url('/public//images/box-blue.png') no-repeat center top;
  background-size: cover;
}
.bk-doza{
  @apply mx-auto text-white w-full lg:w-[1200px] lg:h-[499px];
  background: url('/public//images/bk-doza.png') no-repeat center top;
  @apply pb-8 bg-cover lg:bg-contain lg:pb-0;
}
.bk-gama{
  background: url('/public//images/gama.png') no-repeat center top;
  background-size: cover;
  width: 100%;
  @apply lg:h-[840px]
}
.bk-doza h2{
 @apply  py-6 lg:py-4 text-white text-3xl lg:text-[44px] text-center h-[98px];
}
.bk-doza ul{
  @apply flex w-full text-center text-white ;
}
.bk-doza ul li{
  @apply px-4 font-[300] pt-6 text-base lg:text-[24px];
}
.bk-doza ul li:nth-child(2){
  @apply px-4 ;
}
.bk-doza p{
  @apply font-[300] pt-8 text-base lg:text-[20px]
}